<template>
    <vx-card title="Purchase Order">
        <!-- <div class="vx-row mb-6" style="width: 70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <multiselect v-model="territory.selected" :options="territory.options" placeholder="Type to search"
                    track-by="name" label="name" :max-height="125">
                    <span slot="noResult">Oops! No data found</span>
                    <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                        </span>
                    </template>

                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                        </div>
                    </template>
                </multiselect>
            </div>
        </div> -->
        <div class="vx-row mb-12">
            <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
                    :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
                    <template slot="header">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div
                                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                                    {{ this.table.total }}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="(item, index) in table.limits" v-bind:key="index"
                                    @click="handleChangelength(item)">
                                    <span>{{ item }}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <div class="btn-group ml-5">
                            <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Export Excel
                            </vs-button>
                            <!-- <vs-button size="small" color="primary" type="border" @click="handleExport('pdf')">PDF
                            </vs-button> -->
                        </div>
                    </template>
                    <template slot="thead">
                        <vs-th>Logistic Area</vs-th>
                        <vs-th>Warehouse</vs-th>
                        <vs-th sort-key="supplier_name">Supplier</vs-th>
                        <vs-th sort-key="po_number">PO Number</vs-th>
                        <vs-th sort-key="inv_number">Inv Number</vs-th>
                        <vs-th sort-key="po_date">PO Date</vs-th>
                        <vs-th sort-key="inv_date">Inv Date</vs-th>
                        <vs-th sort-key="subtotal">PO Value</vs-th>
                        <!-- <vs-th sort-key="tax">Tax Value</vs-th> -->
                        <vs-th>Receive Value</vs-th>
                        <vs-th sort-key="inv_value">Inv Value</vs-th>
                        <vs-th>Reject Value</vs-th>
                        <vs-th>% Reject</vs-th>
                        <vs-th sort-key="status_match">3 Way Match</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].logistic_area">{{data[indextr].logistic_area}}</vs-td>
                            <vs-td :data="data[indextr].warehouse">{{data[indextr].warehouse}}</vs-td>
                            <vs-td :data="data[indextr].supplier">{{data[indextr].supplier}}</vs-td>
                            <vs-td :data="data[indextr].po_number">{{data[indextr].po_number}}</vs-td>
                            <vs-td :data="data[indextr].inv_number">{{data[indextr].inv_number}}</vs-td>
                            <vs-td :data="data[indextr].po_date">{{data[indextr].po_date}}</vs-td>
                            <vs-td :data="data[indextr].inv_date">{{data[indextr].inv_date}}</vs-td>
                            <vs-td :data="data[indextr].po_value">{{data[indextr].po_value}}</vs-td>
                            <!-- <vs-td :data="data[indextr].tax_value">{{data[indextr].tax_value}}</vs-td> -->
                            <vs-td :data="data[indextr].receive_value">{{data[indextr].receive_value}}</vs-td>
                            <vs-td :data="data[indextr].inv_value">{{data[indextr].inv_value}}</vs-td>
                            <vs-td :data="data[indextr].reject_value">{{data[indextr].reject_value}}</vs-td>
                            <vs-td :data="data[indextr].percent_reject">{{data[indextr].percent_reject}}</vs-td>
                            <vs-td :data="data[indextr].way_match">{{data[indextr].way_match}}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
            </div>
            <!-- detail-->
            <transition name="detail-fade">
                <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
                    <div>
                        <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth"
                            vs-align="rigth" vs-w="4">
                            <vs-button class="ml-auto" size="small" v-on:click="closeDetail" color="grey"
                                icon-pack="feather" icon="icon-x-square">Close</vs-button>
                        </vs-col>
                        <vs-col class="vertical-divider" vs-offset="0" vs-type="flex" vs-justify="center"
                            vs-align="center" vs-w="1">
                            <vs-button size="small" v-on:click="closeDetail" color="grey" icon-pack="feather"
                                icon="icon-x-square">
                            </vs-button>
                        </vs-col>
                    </div>
                </div>
            </transition>
        </div>
    </vx-card>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                table: this.tableState(),
                companyCode: "",
                warehouseCode: "",
                skuCode: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                item: null,
                territory: {
                    selected: null,
                    options: [],
                },
                territoryCode: "",
            };
        },
        methods: {
            tableState() {
                return {
                    data: [],
                    length: 10,
                    page: 1,
                    search: "",
                    order: "po_date",
                    sort: "desc",
                    total: 0,
                    totalPage: 0,
                    totalSearch: 0,
                    limits: [10, 25, 50, 100, "All"],
                    start: 1,
                    end: 0,
                };
            },
            handleSearch(searching) {
                this.table.search = searching;
                this.table.page = 1;
                this.getData();
            },
            handleChangePage(page) {
                this.table.page = page;
                this.getData();
            },
            handleSort(key, active) {
                if (active) {
                    this.table.order = key;
                    this.table.sort = active;
                } else {
                    this.table.order = "po_date";
                    this.table.sort = "desc";
                }
                this.getData();
            },
            handleChangelength(val) {
                this.table.length = val == "All" ? this.table.total : val;
                this.table.page = 1;
                this.getData();
            },
            setStartEnd() {
                let valStart =
                    this.table.length * this.table.page - this.table.length + 1;
                if (valStart > this.table.total) {
                    valStart = 1;
                }
                if (this.table.total == 0) {
                    valStart = 0;
                }
                let valEnd = this.table.length * this.table.page;
                if (valEnd > this.table.total) {
                    valEnd = this.table.total;
                }
                if (this.totalSearch < this.total) {
                    valEnd = this.table.totalSearch;
                }

                this.table.start = valStart;
                this.table.end = valEnd;
            },
            getData() {
                this.$vs.loading();
                this.$http
                    .get("/api/v1/report/purchase-order", {
                        params: {
                            length: this.table.length,
                            page: this.table.page,
                            search: this.table.search,
                            order: this.table.order,
                            sort: this.table.sort,
                            territoryCode: this.$userLogin.territory_code,
                        },
                    })
                    .then((resp) => {
                        if (resp.status == "success") {
                            this.table.total = resp.data.recordTotal;
                            this.table.totalPage = resp.data.pageTotal;
                            this.table.totalSearch = resp.data.recordTotalSearch;
                            this.table.length = resp.data.recordTotalPerPage;
                            this.table.data = resp.data.records;
                            this.setStartEnd();
                            this.$vs.loading.close();
                        } else {
                            console.log(resp.data);
                        }
                    });
            },
            closeDetail() {
                this.item = null;
                this.detail = false;
            },
            // getAllDataTerritory() {
            //     this.$vs.loading();
            //     this.$http
            //         .get("/api/v1/master/territory", {
            //             params: {
            //                 order: "code",
            //                 sort: "asc",
            //             },
            //         })
            //         .then((resp) => {
            //             if (resp.status == "success") {
            //                 this.territory.options = resp.data.records;
            //                 if (this.territory.options.length > 0) {
            //                     this.territory.selected = this.territory.options[0];
            //                 }
            //                 this.$vs.loading.close();
            //             } else {
            //                 this.$vs.loading.close();
            //                 console.log(resp.data);
            //             }
            //         });
            // },
            handleExport(file) {
                // this.$vs.loading();
                this.$http
                    .get("/api/v1/report/purchase-order/export", {
                        params: {
                            territoryCode: this.$userLogin.territory_code,
                            file: file,
                            order: "po_date",
                            length: "all",
                        },
                        responseType: "arraybuffer",
                    })
                    .then((resp) => {
                        var fileURL = window.URL.createObjectURL(new Blob([resp]));
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        if (file == "excel") {
                            fileLink.setAttribute(
                                "download", this.$userLogin.territory_code + "PO.xlsx"
                            );
                        } else {
                            // fileLink.setAttribute(
                            //     "download", this.$userLogin.territory_code + "PO.pdf"
                            // );
                        }
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            },
        },
        mounted() {
            // this.getAllDataTerritory();
            this.getData();
        },
        watch: {
            // "territory.selected": function (v) {
            //     if (v) {
            //         this.territoryCode = v.code;
            //         this.getData();
            //     } else {
            //         this.table = this.tableState();
            //     }
            // },
        },
        computed: {
            setPage: {
                get() {
                    return 1;
                },
                set(val) {
                    if (val != this.table.page) {
                        this.handleChangePage(val);
                    }
                },
            },
        },
    };
</script>

<style scoped>
    .core-enter-active {
        transition: all 0.3s ease;
    }

    .core-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */
        {
        transform: translateX(10px);
        opacity: 0;
    }

    .core {
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }

    .vs-con-table .vs-con-tbody .vs-table--tbody-table {
        font-size: 12px;
    }

    .vertical-divider {
        border-left: 1px solid #7367f0;
        min-height: 800px;
        padding: 5px;
    }
</style>